var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterQuotations',{attrs:{"company":_vm.company},on:{"filtersQuotation":_vm.filtersQuotation}})],1),_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.quotations,"sort-by":"date"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Cotizaciones Canceladas")]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.exportExcel}},[_vm._v("mdi-download")])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, 10))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.slice(0, 10))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de cotiaciones aún ")]},proxy:true},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/clients/client/'+ item.companyID}}},[_vm._v(_vm._s(item.company))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pa-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(item.items!=undefined)?_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f7f7f7"},attrs:{"headers":_vm.headers2,"items":item.items,"dense":""},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items(item.item))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prices(item.item).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.prices(item.item)*item.quantity).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}}],null,true)}):_vm._e()],1),_c('v-col',{staticClass:"px-6",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"px-4 py-3",staticStyle:{"background-color":"#ffedb3","border-radius":"0px 10px 10px 10px","min-height":"120px"}},[_c('strong',[_vm._v("Notas:")]),_c('br'),_c('span',[_vm._v(_vm._s(item.note))])])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[(item.pdf!=undefined)?_c('v-btn',{staticStyle:{"margin":"auto","display":"block"},attrs:{"href":'https://backend.unocrm.mx/files/' + item.pdf,"target":"_blank","text":""}},[_vm._v(" PDF: "),_c('br'),_c('v-icon',[_vm._v("mdi-file-pdf-outline")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-btn',{staticClass:"mb-4",staticStyle:{"font-size":"12px","height":"30px!important","margin-left":"auto","margin-right":"auto","display":"block"},attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.confirmStatus(item.id, 'quotation')}}},[_vm._v("Retomar")])],1)],1)],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet2),callback:function ($$v) {_vm.sheet2=$$v},expression:"sheet2"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas reactivar la cotizacion cancelada? "),_c('br'),_c('span',{staticStyle:{"font-size":"13px","color":"grey"}},[_vm._v("Esto movera la cotización cancelada a la sección de \"Cotizaciones\"")])]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.status()}}},[_vm._v(" Si ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" No ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }